import {Button, CircularProgress, Typography} from "@mui/material"
import {array, func, object} from "prop-types"
import {useRef} from "react"
import {FaCheck as CheckIcon} from "react-icons/fa"
import {Link} from "react-router-dom"

import useFeatures from "lib/hooks/use-features"

import {cancelTargetingImpact, requestTargetingImpact} from "../../lib/api.js"
import Box from "../box/box"
import {conditionValidator} from "./targeting-condition-helpers"
import TargetingImpactMessage from "./targeting-impact-message.jsx"
import {targetingStyles} from "./targeting-styles"
import {useTemplateTargeting} from "./template-targeting-context"
import {FORM_STATES, TARGETING_IMPACT} from "./template-targeting-helpers"
import {openUpdateModal} from "./template-targeting-modal-reducer"

const TargetingImpact = ({dispatchModal, groups, template}) => {
  const {hasFeature} = useFeatures()
  const classes = targetingStyles()

  const {
    formState,
    isLoading,
    isTargetingPriority,
    journeyCreationStrategy,
    targetingImpact,
    saveTargeting,
    setFormState,
    setTargetingImpact,
    targetingGroups,
    targetingGroupLogicalOperator,
  } = useTemplateTargeting()

  const templateId = template?.id

  const targetingImpactCount = targetingImpact?.numContacts || 0

  const submitButtonLabel = () => {
    switch (formState) {
      case FORM_STATES.CHECKING_IMPACT:
        return "Cancel Checking Impact..."
      case FORM_STATES.READY_TO_SUBMIT:
      case FORM_STATES.PRIOR_IMPACT:
        return hasFeature("campaign-approval")
          ? `Save + Add ${targetingImpactCount.toLocaleString()} Matching Contacts once Launched`
          : `Save + Add ${targetingImpactCount.toLocaleString()} Contacts to this Campaign`
      case FORM_STATES.SUBMITTING:
        return "Saving..."
      case FORM_STATES.COMPLETE:
        return "Saved!"
      default:
        if (FORM_STATES.REMOVE === formState && targetingGroups.length === 0) return "Save"
        return "Check Impact..."
    }
  }

  const saveConditions = () => saveTargeting(template, groups)

  // This submitButton ref exists to serve rendering for ChromicPDF.
  // We use it to check whether the submitButton is disabled or not, so we
  // can click to retrieve the impact results.
  const submitButton = useRef(null)

  // This function exists to serve rendering for ChromicPDF,
  // ready-to-print lets us know when the impact results have
  // been fetched and the impact count animation has finished,
  // so ChromicPDF can take a screenshot.
  const readyToPrint = ({number}) => {
    if (number === targetingImpactCount)
      document.getElementById("root")?.setAttribute("ready-to-print", "true")
  }

  const onSubmit = () => {
    if (!templateId) return

    if (
      [FORM_STATES.CLEAN, FORM_STATES.DIRTY, FORM_STATES.ERROR].includes(formState) ||
      (FORM_STATES.REMOVE === formState && targetingGroups.length > 0)
    ) {
      requestImpact()
      return
    }

    if (formState === FORM_STATES.CHECKING_IMPACT) {
      cancelTargetingImpact(template.id)
      setTargetingImpact(null)
      setFormState(FORM_STATES.CLEAN)
      return
    }

    /**
     * Reason we are using template.splitTestingGroupId and not groupId here
     * is because it is possible the user has selected a new groupId, but
     * hasn't saved the new group yet.
     */
    const splitTestingGroup =
      template.splitTestingGroupId &&
      groups.find(group => group.id === template.splitTestingGroupId)

    /**
     * Check to see if there are any templates in the group besides the
     * current template.
     */
    if (
      splitTestingGroup &&
      splitTestingGroup.templates &&
      splitTestingGroup.templates.filter(t => t.id !== template.id).length > 0
    ) {
      dispatchModal(openUpdateModal(true))
      return
    }

    saveConditions()
  }

  const requestImpact = () => {
    setTargetingImpact({...targetingImpact, state: TARGETING_IMPACT.NONE})
    setFormState(FORM_STATES.CHECKING_IMPACT)

    requestTargetingImpact(template.id, {
      isTargetingPriority,
      journeyCreationStrategy,
      targetingGroups,
      targetingGroupLogicalOperator,
    }).catch(() => {
      setTargetingImpact({
        ...targetingImpact,
        numContacts: 0,
        completedAt: null,
        state: TARGETING_IMPACT.FAILED,
      })
      setFormState(FORM_STATES.ERROR)
    })
  }

  return (
    <Box>
      <div className={classes.impactContainer}>
        <TargetingImpactMessage classes={classes} onComplete={readyToPrint} state={formState} />
      </div>
      <div className={classes.actions}>
        {formState === FORM_STATES.PRIOR_IMPACT && (
          <Button
            style={{marginRight: "20px"}}
            onClick={() => {
              setFormState(FORM_STATES.CLEAN)
              setTargetingImpact(null)
            }}
          >
            Edit conditions
          </Button>
        )}
        <Button
          classes={{label: classes.buttonLabel}}
          color="primary"
          disabled={
            isLoading ||
            [FORM_STATES.SUBMITTING, FORM_STATES.COMPLETE, FORM_STATES.ERROR].includes(formState) ||
            (targetingGroups.length === 0 && formState !== FORM_STATES.REMOVE) ||
            targetingGroups.some(
              ({targetingConditions}) =>
                targetingConditions.length === 0 ||
                targetingConditions.some(
                  ({operator, value}) => !conditionValidator(operator, value)
                )
            )
          }
          onClick={onSubmit}
          ref={submitButton}
          type="submit"
          variant="contained"
        >
          {formState === FORM_STATES.SUBMITTING && (
            <CircularProgress className={classes.buttonIcon} size={14} />
          )}
          {formState === FORM_STATES.COMPLETE && <CheckIcon className={classes.buttonIcon} />}
          {submitButtonLabel()}
        </Button>
      </div>
      <footer className={classes.footer}>
        <Typography className={classes.footerText}>
          You can choose what order campaigns are evaluated in by going to the
        </Typography>
        <Typography color="primary" component={Link} to="/admin/templates/targeting-order">
          targeting order
        </Typography>
        .
      </footer>
    </Box>
  )
}

TargetingImpact.propTypes = {
  dispatchModal: func.isRequired,
  groups: array.isRequired,
  template: object.isRequired,
}

export default TargetingImpact
